export const initTeamData = {
    matches: 0,
    wins: 0,
    winsOT: 0,
    loses: 0,
    losesOT: 0,
    "goals-scored": 0,
    "goals-conceded": 0,
    points: 0,
};

export const groupACountries = [
    "Švajčiarsko",
    "Nórsko",
    "Česko",
    "Fínsko",
    "Veľká Británia",
    "Kanada",
    "Rakúsko",
    "Dánsko"
];

export const groupBCountries = [
    "Slovensko",
    "Nemecko",
    "Švédsko",
    "USA",
    "Francúzsko",
    "Kazachstan",
    "Poľsko",
    "Lotyšsko"
];
