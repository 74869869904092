// countries.js

const countries = {
    "Švajčiarsko": "ch",
    "Nórsko": "no",
    "Česko": "cz",
    "Fínsko": "fi",
    "Veľká Británia": "gb",
    "Kanada": "ca",
    "Rakúsko": "at",
    "Dánsko": "dk",
    "Slovensko": "sk",
    "Nemecko": "de",
    "Švédsko": "se",
    "USA": "us",
    "Francúzsko": "fr",
    "Kazachstan": "kz",
    "Lotyšsko": "lv",
    "Poľsko": "pl",
};

export default countries;
